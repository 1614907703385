
$(function(){
  // サービスエージェント
  var apiUrlOK = $("input[name=apiAgentPermissionOK]").val();
  var apiUrlNG = $("input[name=apiAgentPermissionNG]").val();
  var agentTabTopURL = $("input[name=agentTabTopURL]").val();

  $('.js_triggerAgentPermissionOK').on('click',function(){
    var data = {};
    var tokenName = $("input[id=CSRFToken]").attr('name');
    var tokenValue = $("input[id=CSRFToken]").val();
    data[tokenName] = tokenValue;

    $(window).ajSendByDataOnly(apiUrlOK,data,function(res){
      if (res['succuess'] == false) {
        agentTabTopURL = agentTabTopURL + '?errorCode=' + res['errorCode'];
      }

      // エージェントタブトップにリダイレクト
      window.location.assign(agentTabTopURL);
    });
  });

  $('.js_triggerAgentPermissionNG').on('click',function(){
    var data = {};
    var tokenName = $("input[id=CSRFToken]").attr('name');
    var tokenValue = $("input[id=CSRFToken]").val();
    data[tokenName] = tokenValue;

    $(window).ajSendByDataOnly(apiUrlNG,data,function(){
      // モーダルを閉じる
      $(".md_modalSet").hide();
    });
  });

  // モーダル外をクリックしたときにモーダルを閉じる
  $(document).click(function (event) {
    if(!$(event.target).closest(".js_agentPermissionModalClose").length &&
        ($(".md_modalSet").css("display") == "block")) {
      $(".md_modalSet").hide();
      // not_display_agent_permission_modalをcookieに保存
      document.cookie = "not_display_agent_permission_modal=1; path=/; max-age=86400;";
    }
  });
});