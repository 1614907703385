/**
 * form要素を親にとり、指定したurlにデータを送信する。
 * @class myAjaxFunc
 * 
**/



$.fn.extend({
/**
* 指定したフォームを、指定したURLへajax送信します。（POST）
* JSONファイルを返すAPI用。
* @method $(form).ajSendByForm
* @param url {文字列} データ送信先URL
* @param func(data) {関数} データ通信成功時の実行関数（dataはjsonで返ってきます。）
* @param loading {DOM} ローディング中に表示するオブジェクト
*
**/
    ajSendByForm : function(url,func,loading){
        var postData = this.serialize();
        myAjaxFunc.mySendFormAjax(postData,url,func,loading,true);
    },

/**
* 指定したデータオブジェクトを、指定したURLへajax送信します。（POST）
* JSONファイルを返すAPI用。
* @method $("").ajSendByData
* @param url {文字列} データ送信先URL
* @param data {配列} 送信データ
* @param func(data) {関数} データ通信成功時の実行関数（dataはjsonで返ってきます。）
* @param loading {DOM} ローディング中に表示するオブジェクト
*
**/
    ajSendByData : function(url,data,func,loading){
        myAjaxFunc.mySendFormAjax(data,url,func,loading,true);
    },


/**
* 指定したフォームを、指定したURLへajax送信します。（POST）
* 通常テキストを返すAPI用。
* @method $("").ajSendByFormOnly
* @param url {文字列} データ送信先URL
* @param func(data) {関数} データ通信成功時の実行関数（dataはテキストで返ってきます。）
* @param loading {DOM} ローディング中に表示するオブジェクト
*
**/
    ajSendByFormOnly : function(url,func,loading){
        var postData = this.serialize();
        myAjaxFunc.mySendFormAjax(postData,url,func,loading,false);
    },

/**
* 指定したフォームを、指定したURLへajax送信します。（POST）
* 通常テキストを返すAPI用。
* @method $("").ajSendByDataOnly
* @param url {文字列} データ送信先URL
* @param func(data) {関数} データ通信成功時の実行関数（dataはテキストで返ってきます。）
* @param loading {DOM} ローディング中に表示するオブジェクト
*
**/
    ajSendByDataOnly : function(url,data,func,loading){
        myAjaxFunc.mySendFormAjax(data,url,func,loading,false);
    },
    ajContentLoad : function(url,func,loading,data){
        myAjaxFunc.myContentLoad(url,func,loading,data);
    }
})

var myAjaxFunc = {
    mySendFormAjax : function(postData,jqUrl,jqFunc,jqLoadingIcon,jsonFlg){
        if(!jqLoadingIcon){
            jqLoadingIcon = null;
        }else{
    		jqLoadingIcon.show();
        }
        if(jsonFlg == true){
            var _type = "json";
        }else{
            var _type = "";
        }
		$.ajax({
			type: 'post',
			url: jqUrl,
			data: postData,
			cache: false,
			async: true,
            dataType: _type
		}).done(function(returnData){
            jqFunc(returnData);
		}).fail(function(returnData){
		}).always(function(returnData){
            if(!jqLoadingIcon){
                jqLoadingIcon = null;
            }else{
        		jqLoadingIcon.hide();
            }
		});
    },
    myContentLoad : function(jqUrl,jqCallback,jqLoadingIcon,jqData){
        if(!jqLoadingIcon){
            jqLoadingIcon = null;
        }else{
    		jqLoadingIcon.show();
        }
        if(jqData == undefined){
            jqData = {};
        }
		$.ajax({
			type: 'post',
			url: jqUrl,
			cache: false,
			async: true,
            data: jqData
		}).done(function(returnData){
            jqCallback(returnData);
		}).fail(function(returnData){
		}).always(function(returnData){
            if(!jqLoadingIcon){
                jqLoadingIcon = null;
            }else{
        		jqLoadingIcon.hide();
            }
		});
    }
}